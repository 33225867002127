import { MutableRefObject, useRef, useEffect } from 'react'

import { useNavigate } from 'react-router-dom'

import { useUserContext } from './user.context'
import { routeNames } from '../configs/routePaths'
import { createContext } from '../utils/create-context'
import Constants from '@/configs/constants'
import { createConnectionPool } from '@/utils/createConnectionPool'

interface IContextProps {
  iframeRef: MutableRefObject<HTMLIFrameElement | undefined>
  sendMessage: (body?: any, action?: string) => void
}

interface IProviderProps {
  children: JSX.Element
}

interface IPostMessageProps {
  body?: any
  action?: string
}

export interface ISignalRProps {
  IsAbleToUseAsynchronousReports: boolean
  AccessInformations: AccessInformations
  RelatorioHub: string
  CommunicateUrl: string
}

export interface AccessInformations {
  Mensagem: any
  Erro: boolean
  Data: Data
}

export interface Data {
  IdGrupoEconomico: string
  IdDoUsuarioSolicitante: string
  IdDoAplicativoOnix: string
}

const [usePlataformContext, PlatformContext] = createContext<IContextProps>()

const PlatformProvider = ({ children }: IProviderProps) => {
  const { logOut } = useUserContext()
  const iframeRef = useRef<HTMLIFrameElement>()
  const navigate = useNavigate()
  const APP_URL = process.env.VITE_APP_ONIX_URL ?? ''
  const sendMessage = ({ body, action }: IPostMessageProps) => {
    const message = {
      appName: process.env.VITE_APP_NAME,
      type: `${Constants.TYPE_PREFIX}.${action}`,
      body
    }
    iframeRef.current?.contentWindow?.postMessage(
      JSON.stringify(message),
      APP_URL
    )
  }
  useEffect(() => {
    window.addEventListener('message', function (content) {
      if (content.data.type === `${Constants.PARENT_PREFIX}.ONESIGNALR`) {
        const body: ISignalRProps = JSON.parse(content.data.body)
        if (body.IsAbleToUseAsynchronousReports) {
          setTimeout(() => {
            createConnectionPool(body)
          }, 500)
        }
      }
      if (content.origin === process.env.VITE_APP_ONIX_URL) {
        if (content.data.type === Constants.PARENT_LOCATION) {
          return (
            routeNames.homeDashboard.includes(content.data.body) &&
            location.replace(routeNames.dashboard)
          )
        }
        if (content.data.type === `${Constants.TYPE_PREFIX}.ISAUTH`) {
          !content.data.body && navigate(routeNames.root)
          return !content.data.body && logOut()
        }
      }
    })

    return () => {
      window.removeEventListener('message', function () {
        console.log('EVENT LISTENER REMOVED')
        return
      })
    }
  }, [])

  return (
    <PlatformContext
      value={{
        iframeRef,
        sendMessage
      }}
    >
      {children}
    </PlatformContext>
  )
}

export { PlatformProvider, usePlataformContext }
