import { Navigate, Outlet } from 'react-router-dom'

import ContentHeader from '@/components/structure/content-header'
import { routeNames } from '@/configs/routePaths'
import { useUserContext } from '@/contexts/user.context'

export function WithAuth() {
  const { isAuthenticated } = useUserContext()

  if (!isAuthenticated) {
    return (
      <>
        <Navigate to={routeNames.login} replace />
      </>
    )
  }

  return (
    <>
      <ContentHeader />
      <Outlet />
    </>
  )
}
