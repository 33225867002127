import { UseQueryResult, useQuery } from 'react-query'

import { QueryKey } from '@/configs/queryKeys'
import { getAccounts } from '@/services/accounts'

export function useGetAccountsData({
  page,
  pageSize,
  SearchTerm
}: {
  page: number
  pageSize: number
  SearchTerm: string
}): UseQueryResult<any> {
  return useQuery(
    [QueryKey.accounts, { page, pageSize, SearchTerm }],
    getAccounts,
    {
      staleTime: 1000 * 60 * 60 * 24, // 1 day,
      keepPreviousData: true,
      cacheTime: 10 * (60 * 1000), // 10 mins
      retry: false,
      refetchOnReconnect: false
    }
  )
}
