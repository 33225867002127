import { Label, Select } from 'flowbite-react'
import { FormikValues } from 'formik'

interface CustomSelectProps
  extends React.SelectHTMLAttributes<HTMLSelectElement> {
  formik: FormikValues
  label: string
  field: string
  options: { value: string; label: string }[]
  placeholder?: string
}

const CustomSelect = ({
  formik,
  field,
  placeholder,
  options,
  label,
  ...props
}: CustomSelectProps) => {
  return (
    <>
      {label && <Label>{label}</Label>}
      <Select
        id={field}
        color={formik.touched[field] && formik.errors[field] ? 'failure' : ''}
        helperText={formik.touched[field] && formik.errors[field]}
        {...formik.getFieldProps(field)}
        {...props}
      >
        <option value='' selected>
          {placeholder ?? 'Selecione...'}
        </option>
        {options?.map((option, index) => (
          <option value={option?.value} key={index}>
            {option?.label}
          </option>
        ))}
      </Select>
    </>
  )
}

export default CustomSelect
