import { BlipChat } from 'blip-chat-widget'

import Constants from '@/configs/constants'
import { IBlipChat } from '@/interfaces/user'

export function LoadChat(chat?: IBlipChat) {
  const blipChatInDOM = document.getElementById(Constants.BLIP_REF)
  const isChatEnabled =
    chat?.profileName != 'Interno' &&
    chat?.passwordUser &&
    chat?.email &&
    !blipChatInDOM
  if (isChatEnabled) {
    const builder = new BlipChat()
    builder
      .withAppKey(process.env.VITE_APP_BLIPCHAT_KEY)
      .withCustomMessageMetadata({
        fullName: chat?.userName,
        email: chat?.email,
        phoneNumber: chat?.phoneNumber,
        AccountName: chat?.companyName,
        Equipe: chat?.profileName,
        License: chat?.license,
        Affiliate: chat?.affiliate
      })
      .withButton({
        color: '#00B61A',
        icon: 'https://blipmediastore.blip.ai/public-medias/Media_5781bf8e-d27b-47f6-896c-c3217d28454d'
      })
      .withAuth({
        authType: BlipChat.DEV_AUTH,
        userIdentity: chat?.email,
        userPassword: chat?.passwordUser
      })
      .withAccount({
        fullName: chat?.userName,
        email: chat?.email,
        phoneNumber: chat?.phoneNumber,
        extras: {
          AccountName: chat?.companyName,
          Equipe: chat?.profileName,
          License: chat?.license
        }
      })
      .build()
  }
}
