import { QueryFunctionContext, UseInfiniteQueryResult } from 'react-query'

import { endPoints } from '../configs/endPoints'
import {
  IDepartamentos,
  IDepartamentoItems,
  IDepartamento
} from '../interfaces/departamentos'
import { API } from '../utils/fetcher'
import { IPagination } from '@/interfaces/pagination'

export async function createDepartamentos(payload: IDepartamentos) {
  const { Code, Description } = payload
  const response = await API.post<unknown>(endPoints.departamentos, {
    Code,
    Description
  })

  return response
}

export async function getDepartamentos(
  ctx: QueryFunctionContext<[string, { page: number; pageSize: number }]>
): Promise<IDepartamentoItems> {
  const [, { page, pageSize }] = ctx.queryKey
  const data = await API.get<IDepartamentoItems>(`${endPoints.departamentos}`, {
    page,
    pageSize
  })

  return data
}

export async function getDepartamentosList(
  ctx: QueryFunctionContext<[string, { page: number; pageSize: number }]>
): Promise<IDepartamentoItems> {
  const [, { page, pageSize }] = ctx.queryKey
  const data = await API.get<IDepartamentoItems>(
    `${endPoints.departamentosList}`,
    {
      page,
      pageSize
    }
  )

  return data
}

export async function getDepartamentosInfinite(
  ctx: QueryFunctionContext<[string, { dataQuery: any }]>
): Promise<UseInfiniteQueryResult<IPagination<IDepartamento[]>>> {
  const { pageParam } = ctx
  const [, { dataQuery }] = ctx.queryKey
  const data = await API.get<IDepartamento>(
    `${endPoints.departamentos}/?page=${pageParam || 1}`,
    dataQuery
  )

  return data
}

export async function getDepartamento(
  ctx: QueryFunctionContext<[string, { departamentoId: string }]>
): Promise<IDepartamento> {
  const [, { departamentoId }] = ctx.queryKey
  const data = await API.get<IDepartamento>(
    `${endPoints.departamentos}/${departamentoId}`
  )

  return data
}

export async function editDepartamentos(payload: IDepartamentos) {
  const { Code, Description, departamentoId } = payload
  const response = await API.put<unknown>(
    `${endPoints.departamentos}/${departamentoId}`,
    {
      Code,
      Description
    }
  )

  return response
}

export async function deleteDepartamentos(departamentoId: string) {
  const response = await API.delete(
    `${endPoints.departamentos}/${departamentoId}`
  )
  return response
}
