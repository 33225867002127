import React from 'react'

export function createContext<A>() {
  const initialContext = React.createContext<A | null>(null)

  function useCtx() {
    const context = React.useContext(initialContext)

    if (!context) {
      throw new Error('Context must be inside a Provider with a value')
    }
    return context
  }
  return [useCtx, initialContext.Provider] as const
}
