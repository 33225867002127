import React from 'react'

import { useLocation } from 'react-router-dom'

import Iframe from '@/components/iframe/iframe.component'

const Platform = () => {
  const location = useLocation()
  const platformUrl = process.env.VITE_APP_ONIX_URL
  const iframeURL = React.useMemo(
    () => platformUrl + location.pathname + location?.search,
    [location]
  )
  const [isiframeLoaded, setIsIframeLoaded] = React.useState(false)

  function handleIframeLoad() {
    setIsIframeLoaded(true)
  }

  return (
    <>
      <Iframe
        onLoad={handleIframeLoad}
        url={`${iframeURL}`}
        isVisible={isiframeLoaded}
      />
    </>
  )
}

export default Platform
