enum Constants {
  TOKEN_STORAGE_KEY = '.OnixV2PortalIntegration',
  USER_STORAGE_KEY = 'userInfo',
  USER_LASTROUTER_KEY = 'lastRouter',
  ONIX_STORAGE_KEY = '.AspNet.ApplicationCookieOnixPortal',
  ONIX_SESSION_ID_KEY = 'ASP.NET_SessionId',
  BLIP_REF = 'blip-chat-container',
  USER_ONBOARDING = 'userOnboarding',
  CONTACT_TELVR = '(11) 4004-4938',
  CONTACT_OTERS = '0800 596 3838',
  ERROR_DATA = 'Erro ao processar os dados',
  PARENT_LOCATION = 'PARENT.EVENT.LOCATION',
  TYPE_PREFIX = 'THIRD-PARTY-APP',
  PARENT_PREFIX = 'PARENT.EVENT'
}

export default Constants
