import { Fragment, Suspense } from 'react'

import { Menu, Transition } from '@headlessui/react'
import classNames from 'classnames'
import { Link } from 'react-router-dom'

import { usePlataformContext } from '@/contexts/platform.context'
import { useUserContext } from '@/contexts/user.context'
import { useGetUserData } from '@/hooks/queries/use-get-user-data'

const UserMenu = () => {
  const { sendMessage } = usePlataformContext()
  const { logOut } = useUserContext()
  const { data: userData } = useGetUserData()

  const handleLogout = () => {
    sendMessage({ action: 'LOGOUT' })
    logOut()
  }

  return (
    <Menu as='div' className='relative ml-3'>
      <Suspense>
        <div>
          <Menu.Button className='flex text-sm h-10 w-10'>
            <span className='sr-only'>{userData?.userEmail}</span>
            <img
              className='rounded-[1.4rem] object-cover border-2 border-[#00b11e]'
              src={`https://ui-avatars.com/api/?name=${userData?.userName}?format=svg`}
              alt=''
            />
          </Menu.Button>
        </div>
      </Suspense>
      <Transition
        as={Fragment}
        enter='transition ease-out duration-100'
        enterFrom='transform opacity-0 scale-95'
        enterTo='transform opacity-100 scale-100'
        leave='transition ease-in duration-75'
        leaveFrom='transform opacity-100 scale-100'
        leaveTo='transform opacity-0 scale-95'
      >
        <Menu.Items className='absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none'>
          <Menu.Item>
            {({ active }) => (
              <Link
                to='/select-account'
                className={classNames(
                  active ? 'bg-gray-100' : '',
                  'block px-4 py-2 text-sm text-gray-700 cursor-pointer'
                )}
              >
                Trocar de conta
              </Link>
            )}
          </Menu.Item>
          <Menu.Item>
            {({ active }) => (
              <Link
                to='/UserAccount/ChangePassword'
                className={classNames(
                  active ? 'bg-gray-100' : '',
                  'block px-4 py-2 text-sm text-gray-700 cursor-pointer'
                )}
              >
                Alterar senha
              </Link>
            )}
          </Menu.Item>
          <Menu.Item>
            {({ active }) => (
              <span
                className={classNames(
                  active ? 'bg-gray-100' : '',
                  'text-red-400 block px-4 py-2 text-sm cursor-pointer'
                )}
                onClick={handleLogout}
              >
                Sair
              </span>
            )}
          </Menu.Item>
        </Menu.Items>
      </Transition>
    </Menu>
  )
}

export default UserMenu
