import { useState, useEffect } from 'react'

import { EllipsisVerticalIcon } from '@heroicons/react/24/outline'
import { Tooltip } from 'flowbite-react'
import { Link } from 'react-router-dom'

import 'intro.js/introjs.css'
import ContactModal from '../contact-modal'
import { getNotificationItems } from '@/services/branches'

const ToolTipMenu = () => {
  const [count, setCount] = useState<number>(0)
  const [isContactModalOpen, setContactModalOpen] = useState(false)
  const handleContactModalOpen = () => {
    setContactModalOpen(true)
  }

  useEffect(() => {
    const getNotifications = async () => {
      try {
        const data = await getNotificationItems()
        setCount(data?.count)
      } catch (error) {
        console.log(error)
      }
    }

    getNotifications()
  }, [])
  return (
    <div className='flex cursor-pointer gap-4 justify-center items-center ml-3 md:ml-0'>
      <Tooltip content='Fale conosco' placement='bottom'>
        <span>
          <img
            src='/images/head_phone.svg'
            alt='Logo'
            title='Fale conosco'
            width={20}
            height={20}
            onClick={handleContactModalOpen}
            className='contact-us'
          />
        </span>
      </Tooltip>
      <Tooltip content='Alertas' placement='bottom'>
        <Link
          to='/VersionNewV3/Index'
          title='Informativos/Alertas'
          className='relative notifications'
        >
          {!!count && (
            <span className='bg-secondary text-white rounded-full w-4 h-4 absolute -top-3 -right-1 text-center text-[10px]'>
              {count}
            </span>
          )}
          <img src='/images/bell.svg' alt='Logo' width={20} height={20} />
        </Link>
      </Tooltip>
      <Tooltip content='Manual do usuário' placement='bottom'>
        <a
          href='https://abscardv3helper.azurewebsites.net/vr-transportes/'
          target='_blank'
          rel='noopener noreferrer'
          title='Manual do usuário'
        >
          <img
            src='/images/question_circle.svg'
            alt='Logo'
            width={20}
            height={20}
            className='help-center'
          />
        </a>
      </Tooltip>
      <EllipsisVerticalIcon
        className='text-white space-x-2 hidden'
        width={25}
        height={25}
      ></EllipsisVerticalIcon>
      <ContactModal
        onClose={() => setContactModalOpen(false)}
        isOpen={isContactModalOpen}
      />
    </div>
  )
}

export default ToolTipMenu
