/* eslint-disable react/prop-types */
import { LegacyRef, useState } from 'react'

import Loader from '@/components/Loader'
import { usePlataformContext } from '@/contexts/platform.context'

const Iframe = ({ isLoading = true, isVisible = true, ...props }) => {
  const [isIframeLoading, setIsIframeLoading] = useState(true)
  const { iframeRef } = usePlataformContext()
  function handleOnLoad() {
    setIsIframeLoading(false)
    if (props.onLoad && typeof props.onLoad === 'function') {
      props.onLoad()
    }
  }

  return (
    <>
      <iframe
        title='OnixPortalIframe'
        className={`w-full h-screen ${
          !isVisible || isIframeLoading ? 'hidden' : ''
        }`}
        src={props.url}
        allow='clipboard-write'
        onLoad={handleOnLoad}
        sandbox='allow-scripts allow-popups allow-same-origin allow-forms allow-modals allow-downloads'
        ref={iframeRef as LegacyRef<HTMLIFrameElement> | undefined}
      ></iframe>
      {isIframeLoading && isLoading ? <Loader size={'xl'} /> : ''}
    </>
  )
}

export default Iframe
