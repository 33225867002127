import { QueryFunctionContext } from 'react-query'

import { endPoints } from '@/configs/endPoints'
import {
  ILogisticItems,
  ILogisticItemsList,
  ILogisticsDetail,
  ILogisticStatusList
} from '@/interfaces/logistics'
import { API } from '@/utils/fetcher'

export async function getLogisticList(
  ctx: QueryFunctionContext<
    [
      string,
      { page: number; pageSize: number; groupNumber: number; search: string }
    ]
  >
): Promise<ILogisticItems> {
  const [, { page, pageSize, groupNumber, search }] = ctx.queryKey
  const data = await API.get<ILogisticItems>(
    `${endPoints.logisticList}/${groupNumber}/Items`,
    {
      pagina: page,
      TamanhoDaPagina: pageSize,
      TermoDePesquisa: search
    }
  )

  return data
}

export async function GetLogistic(
  ctx: QueryFunctionContext<[string, { groupNumber: number }]>
): Promise<ILogisticsDetail> {
  const [, { groupNumber }] = ctx.queryKey
  const data = await API.get<ILogisticsDetail>(
    `${endPoints.logisticItem}/${groupNumber}`
  )

  return data
}

export async function getLogistic(
  ctx: QueryFunctionContext<
    [
      string,
      {
        params: {
          page: number
          ItemsPerPage: number
          AccountId?: string
          DeliveryStatus?: string
          StartSalesOrderCreatedOn?: string
          EndSalesOrderCreatedOn?: string
          Address?: string
          BranchCnpj?: string
          OrderNumber?: string
        }
      }
    ]
  >
): Promise<ILogisticItemsList> {
  const [, { params }] = ctx.queryKey
  const data = await API.get<ILogisticItemsList>(`${endPoints.logistic}`, {
    ...params
  })

  return data
}

export async function getLogisticStatusList(): Promise<ILogisticStatusList[]> {
  const data = await API.get<ILogisticStatusList[]>(
    endPoints.logisticStatusList
  )

  return data
}
