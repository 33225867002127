'use client'

import { useState } from 'react'

import {
  InformationCircleIcon,
  PencilIcon,
  TrashIcon
} from '@heroicons/react/24/outline'
import classNames from 'classnames'
import { Table } from 'flowbite-react'
import { Link, useNavigate } from 'react-router-dom'

import { theme } from './theme'
import CustomActionsMenu from '../custom-action'
import DeleteModal from '../delete-modal/index'

interface GenericTableProps {
  data: Record<string, any>[]
  headers: { [key: string]: string }
  deleteAction: any
  idKey?: string | 'id'
  isIdle?: boolean
  isRowClicable?: boolean
  isActions?: boolean
  hasCustomActions?: boolean
  customActions?: {
    title: string
    action: (id?: any) => void
  }[]
}

export default function TableItem({
  data,
  headers,
  deleteAction,
  isIdle = false,
  isActions = true,
  isRowClicable = false,
  idKey,
  hasCustomActions = false
}: GenericTableProps) {
  const navigate = useNavigate()
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [selectedId, setSelectedId] = useState<string>('')
  const handleDeleteClick = () => deleteAction(selectedId)
  const handleToggleModal = () => setIsOpen(item => !item)
  const handleOpenModal = (id: string) => {
    setSelectedId(id ?? '')
    handleToggleModal()
  }

  if (!isIdle && data?.length === 0) {
    return (
      <div className='p-5 bg-gray-200 flex w-full justify-center items-center text-center rounded'>
        <InformationCircleIcon width={18} height={18} className='mr-2' />
        <p className='text-gray-900'>Nenhum dado disponível</p>
      </div>
    )
  }

  return (
    <>
      <div
        className={`overflow-x-scroll md:overflow-x-scroll transition-opacity ${
          isIdle ? 'opacity-70 pointer-events-none' : ''
        }`}
      >
        <Table hoverable theme={theme} className='shadow rounded-lg'>
          <Table.Head className='border-b-[1px] '>
            {Object.values(headers).map((header, index) => (
              <Table.HeadCell
                align='left'
                className='bg-white text-gray-600'
                key={index}
              >
                {header}
              </Table.HeadCell>
            ))}
            {isActions && (
              <Table.HeadCell className='bg-white text-gray-600'>
                <span className='sr-only'>Edit</span>
              </Table.HeadCell>
            )}
          </Table.Head>
          <Table.Body className='divide-y'>
            {data.map((item, index) => (
              <Table.Row
                className={classNames(
                  item?.isRowClicable && 'cursor-pointer',
                  'bg-white dark:border-gray-700 dark:bg-gray-800'
                )}
                key={index}
              >
                {Object.keys(headers).map(header => (
                  <Table.Cell
                    align='char'
                    className='whitespace-nowrap relative'
                    key={header}
                    onClick={() =>
                      (
                        item?.isRowClicable === undefined
                          ? isRowClicable
                          : item.isRowClicable
                      )
                        ? navigate(`edit/${item[idKey ?? 'id']}`)
                        : null
                    }
                  >
                    <>
                      {(
                        item?.isRowClicable != undefined
                          ? item.isRowClicable
                          : !isRowClicable
                      ) ? (
                        item[header]
                      ) : (
                        <span>
                          {item[header]}
                          {(item?.isRowClicable === undefined
                            ? isRowClicable
                            : !item.isRowClicable) && (
                            <div className='absolute w-full h-full left-0 top-0 bg-white opacity-50 pointer-events-none'></div>
                          )}
                        </span>
                      )}
                    </>
                  </Table.Cell>
                ))}
                {isActions && (
                  <Table.Cell className='flex justify-center items-center gap-5 h-full'>
                    <span className='font-medium text-cyan-600 hover:underline dark:text-cyan-500'>
                      <Link to={`edit/${item[idKey ?? 'id']}`}>
                        <PencilIcon
                          width={16}
                          height={16}
                          className='text-gray-800'
                        />
                      </Link>
                    </span>
                    <span className='font-medium cursor-pointer text-cyan-600 hover:underline dark:text-cyan-500'>
                      <TrashIcon
                        onClick={() => handleOpenModal(item[idKey ?? 'id'])}
                        width={16}
                        height={16}
                        className='text-red-600'
                      />
                    </span>
                    {hasCustomActions && <CustomActionsMenu />}
                  </Table.Cell>
                )}
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      </div>
      <DeleteModal
        isOpen={isOpen}
        onClose={handleOpenModal}
        onDelete={handleDeleteClick}
      />
    </>
  )
}
