import {
  ClipboardIcon,
  ShoppingCartIcon,
  TicketIcon,
  UsersIcon
} from '@heroicons/react/24/outline'
import { Datepicker } from 'flowbite-react'
import { LazyLoadImage } from 'react-lazy-load-image-component'

import ContactBanner from '@/components/contact-banner'
import ShortcurtCard from '@/components/dashboard/shortcut-card.component'
import SkeletonCard from '@/components/skeletom/skeleton.component'
import { useGetnews } from '@/hooks/queries/use-get-user-data'
import 'react-lazy-load-image-component/src/effects/blur.css'

const DashboardContainer = () => {
  const { data: banner, isLoading } = useGetnews()

  const shortcuts = [
    {
      title: 'Fazer pedido',
      uri: '/DemandsV3/Index?RedirectToTab=createDemandsTab',
      icon: <ShoppingCartIcon width={36} height={36} />,
      active: true
    },
    {
      title: 'Acompanhar pedido',
      uri: '/DemandsV3/Index?RedirectToTab=demandsTab',
      icon: <ClipboardIcon width={36} height={36} />,
      active: true
    },
    {
      title: 'Minhas solicitações',
      uri: '/Services/Index',
      icon: <TicketIcon width={36} height={36} />,
      active: true
    },
    {
      title: 'Beneficiários',
      uri: '/EmployeeBaseV3/Index',
      icon: <UsersIcon width={36} height={36} />,
      active: true
    }
  ]

  return (
    <>
      <main
        className='w-full bg-gray-100 md:h-full h-auto'
        data-testid='dashboard-main'
      >
        <div className='max-w-[1100px] w-full flex mx-auto bg-gray-100'>
          <div className='grid md:grid-cols-12 gap-5 w-full my-5 p-5'>
            <div className='md:col-span-8 col-span-12 flex items-center justify-center'>
              {isLoading ? (
                <SkeletonCard />
              ) : (
                <>
                  <LazyLoadImage
                    alt={'Dashboard Banner'}
                    width={'100%'}
                    height={'auto'}
                    src={banner?.imageUri}
                    effect='blur'
                    className='rounded-lg h-auto w-auto cursor-pointer'
                    onClick={() => window.open(banner?.linkUri, '_blank')}
                  />
                </>
              )}
            </div>
            <div className='md:col-span-4 col-span-12 -pt-2 flex justify-center md:justify-end items-center'>
              <Datepicker
                language='pt-BR'
                inline
                showTodayButton={false}
                showClearButton={false}
                className='rounded-xl'
              />
            </div>
            <div className='col-span-12'>
              <div className='text-green-900 font-semibold text-xl mb-1'>
                <span>Meus atalhos</span>
              </div>
              <div className='md:flex grid grid-cols-12 items-center justify-center w-full h-full gap-5'>
                {shortcuts.map((shortcut, index) => (
                  <ShortcurtCard shortcut={shortcut} key={index} />
                ))}
              </div>
            </div>
          </div>
        </div>
        <ContactBanner />
        <div className=' bg-gray-200 p-3 flex mx-auto w-full justify-center items-center'>
          <span className='text-sm text-center font-medium text-gray-600'>
            © VR - Todos os direitos reservados
          </span>
        </div>
      </main>
    </>
  )
}

export default DashboardContainer
