import { QueryFunctionContext } from 'react-query'

import toastService from '@/components/toast/toast-service'
import { endPoints } from '@/configs/endPoints'
import { IAddress } from '@/interfaces/address'
import { API } from '@/utils/fetcher'

export async function getAddress(
  ctx: QueryFunctionContext<[string, { postalCode: string }]>
): Promise<IAddress> {
  const [, { postalCode }] = ctx.queryKey
  const data = await API.get<IAddress>(
    `${endPoints.cep}?PostalCode=${postalCode}`
  ).catch(() => toastService.error('CEP não encontrado'))

  return data
}
