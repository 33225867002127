import { UseQueryResult, useQuery } from 'react-query'

import { QueryKey } from '@/configs/queryKeys'
import {
  ILogisticItems,
  ILogisticItemsList,
  ILogisticsDetail,
  ILogisticStatusList
} from '@/interfaces/logistics'
import {
  getLogistic,
  GetLogistic,
  getLogisticList,
  getLogisticStatusList
} from '@/services/logistics'

export function useGetLogisticsList({
  page,
  pageSize,
  groupNumber,
  search
}: {
  page: number
  pageSize: number
  groupNumber: number
  search: string
}): UseQueryResult<ILogisticItems> {
  return useQuery(
    [QueryKey.logisticList, { page, pageSize, groupNumber, search }],
    getLogisticList,
    {
      keepPreviousData: true,
      suspense: true
    }
  )
}

export function useGetLogisticItem(
  groupNumber: number
): UseQueryResult<ILogisticsDetail> {
  return useQuery([QueryKey.logistic, { groupNumber }], GetLogistic, {
    enabled: Boolean(groupNumber)
  })
}

export function useGetStatusList(): UseQueryResult<ILogisticStatusList[]> {
  return useQuery([QueryKey.logisticStatusList], getLogisticStatusList, {
    keepPreviousData: true
  })
}

export function useGetLogistics({
  params
}: {
  params: {
    page: number
    ItemsPerPage: number
    AccountId?: string | undefined
    DeliveryStatus?: string | undefined
    StartSalesOrderCreatedOn?: string | undefined
    EndSalesOrderCreatedOn?: string | undefined
    Address?: string | undefined
    BranchCnpj?: string | undefined
    OrderNumber?: string | undefined
  }
}): UseQueryResult<ILogisticItemsList> {
  return useQuery([QueryKey.logisticListItems, { params }], getLogistic, {
    keepPreviousData: false,
    retry: false
  })
}
