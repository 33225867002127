import axios, { AxiosError } from 'axios'

import { TokenStorage } from './token-storage'
import { Http } from '../interfaces/http'
import toastService from '@/components/toast/toast-service'
import { routeNames } from '@/configs/routePaths'

export const API_URL = process.env.VITE_APP_API_URL

export const httpAxios = axios.create({
  baseURL: API_URL,
  headers: {
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Headers': '*'
  }
})

httpAxios.interceptors.request.use(config => {
  const TOKEN = TokenStorage.get()
  if (TOKEN) {
    config.headers.Authorization = `bearer ${TOKEN}`
  }

  return config
})

export const API: Http = {
  get: async <T>(path: string, params?: Record<string, any>, config?: any) => {
    const { data } = await httpAxios
      .get(path, { ...config, params })
      .catch(error => Promise.reject(error))
    return data as T
  },
  post: async <T>(path: string, params?: Record<string, any>, config?: any) => {
    const response = await httpAxios
      .post(path, { ...params }, { ...config })
      .catch(error => Promise.reject(error))
    return response.data as T
  },
  put: async <T>(path: string, params?: Record<string, any>, config?: any) => {
    const response = await httpAxios
      .put(path, { ...params }, { ...config })
      .catch(error => Promise.reject(error))
    return response.data as T
  },
  delete: async <T>(path: string, params?: any, config?: any) => {
    const response = await httpAxios
      .delete(path, { ...config, params })
      .catch(error => Promise.reject(error))
    return response.data as T
  }
}

httpAxios.interceptors.response.use(
  function (response) {
    return response
  },
  function (error: AxiosError) {
    switch (error.request.status) {
      case 401:
        toastService.error('Sessão expirada. Faça login novamente')
        location.replace(routeNames.login)
        break
      case 403:
        toastService.error('Você não possui acesso a este recurso')
        location.replace(routeNames.login)
        break
      case 500:
        toastService.error('Error interno')
        break
      default:
        break
    }
    return Promise.reject(error)
  }
)
