import { InformationCircleIcon } from '@heroicons/react/24/outline'
import { Alert } from 'flowbite-react'

import useSessionStorage from '@/hooks/common/use-session-storage'

const LogisticAlerts = () => {
  const [alert, setAlert] = useSessionStorage<string>('isDismissed', '')
  const [logisticAlert, setLogisticAlert] = useSessionStorage<string>(
    'logistic',
    ''
  )
  return (
    <div className='w-full col-span-12 mb-3'>
      <Alert color={'warning'}>
        <div className='flex justify-center'>
          <InformationCircleIcon className='w-5 h-5 mr-2' />
          <span>
            {' '}
            O detalhamento da entrega fica disponível somente após a confirmação
            da coleta na operadora
          </span>
        </div>
      </Alert>
      {alert === '' && (
        <Alert
          color={'warning'}
          className='mt-3'
          onDismiss={() => setAlert('true')}
        >
          <div className='flex justify-center'>
            <InformationCircleIcon className='w-5 h-5 mr-2' />
            <span>
              As entregas da <b>RioCard</b> são realizadas pela própria
              operadora. Para saber o status, entre diretamente em contato com a
              operadora.
            </span>
          </div>
        </Alert>
      )}
      {logisticAlert === '' && (
        <Alert
          color={'warning'}
          className='mt-3'
          onDismiss={() => setLogisticAlert('true')}
        >
          <div className='flex justify-center'>
            <InformationCircleIcon className='w-5 h-5 mr-2' />
            <span>
              {' '}
              Disponíveis somente logísticas geradas à partir de 15/06/2024
            </span>
          </div>
        </Alert>
      )}
    </div>
  )
}

export default LogisticAlerts
