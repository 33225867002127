import { UseQueryResult, useQuery } from 'react-query'

import { QueryKey } from '@/configs/queryKeys'
import { Branch } from '@/interfaces/branches'
import { getBranches } from '@/services/branches'

export function useGetBranchesData(): UseQueryResult<Branch[]> {
  return useQuery([QueryKey.branches], getBranches, {
    cacheTime: Infinity,
    keepPreviousData: true
  })
}
