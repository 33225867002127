import { UseQueryResult, useQuery } from 'react-query'

import { QueryKey } from '@/configs/queryKeys'
import { IAddress } from '@/interfaces/address'
import { getAddress } from '@/services/address'

export function useGetAddress(postalCode: string): UseQueryResult<IAddress> {
  return useQuery([QueryKey.cep, { postalCode }], getAddress, {
    enabled: Boolean(postalCode),
    keepPreviousData: true
  })
}
