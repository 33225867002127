import { UseQueryResult, useQuery } from 'react-query'

import { QueryKey } from '../../configs/queryKeys'
import { IBlipChat, INews, IUser } from '../../interfaces/user'
import { getUser } from '../../services'
import { getAllowedPortalUser, getNews } from '../../services/users'
import { getBliChat } from '@/services/chat'

export function useGetUserData(): UseQueryResult<IUser> {
  return useQuery([QueryKey.profileUser], getUser, {
    cacheTime: 10 * 60
  })
}

export function useGetBlipChat(): UseQueryResult<IBlipChat> {
  return useQuery([QueryKey.blip], getBliChat, {
    cacheTime: 10 * 60
  })
}

export function useGetUserAllowedPortal(): UseQueryResult<IUser> {
  return useQuery([QueryKey.allowedPortal], getAllowedPortalUser)
}

export function useGetnews(): UseQueryResult<INews> {
  return useQuery([QueryKey.news], getNews, {
    enabled: true
  })
}
