import { endPoints } from '../configs/endPoints'
import { INews, IUser } from '../interfaces/user'
import { API } from '../utils/fetcher'

export async function getUser(): Promise<IUser> {
  const data = await API.get<IUser>(endPoints.userData)

  return data
}

export async function getAllowedPortalUser(): Promise<{
  allowedToPortalNewVersion: boolean
}> {
  const data = await API.get<IUser>(endPoints.userAllowed)

  return data
}

export async function getNews(): Promise<INews> {
  const data = await API.get<INews>(endPoints.news)

  return data
}
