const LoginHeader = () => {
  return (
    <div className='flex p-5 bg-rose-600'>
      <div className='container flex items-center md:flex-row flex-col justify-between bg-secondary'>
        <div className='flex md:flex-row md:w-1/2 container-lg'>
          <img src='/images/logo_icon.svg' alt='Logo' width='200px' />
          <p className='text-white font-semibold text-2xl pl-4 hidden md:block'>
            Vale-transporte
          </p>
        </div>
        <div className='md:w-1/2 flex justify-center flex-col md:items-end items-center pt-5 md:pt-0'>
          <p className='text-white text-sm text-center'>
            Vendas Capitais e Regiões Metropolitanas
          </p>
          <p className='text-white text-sm text-center'>
            Vendas Demais Regiões
          </p>
        </div>
      </div>
    </div>
  )
}

export default LoginHeader
