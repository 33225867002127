import {
  UseInfiniteQueryResult,
  UseQueryResult,
  useInfiniteQuery,
  useQuery
} from 'react-query'

import { QueryKey } from '../../configs/queryKeys'
import { ICargo } from '../../interfaces/cargos'
import {
  getCargo,
  getCargos,
  getCargosInfinite,
  getCargosList
} from '../../services/cargos'
import { IPagination } from '@/interfaces/pagination'

export function useGetCargosData({
  page,
  pageSize
}: {
  page: number
  pageSize: number
}): UseQueryResult<any> {
  return useQuery([QueryKey.cargos, { page, pageSize }], getCargos, {
    staleTime: 1000 * 60 * 60 * 24, // 1 day,
    keepPreviousData: true,
    cacheTime: 10 * (60 * 1000), // 10 mins
    retry: false,
    refetchOnReconnect: false
  })
}

export function useGetCargosDataList({
  page,
  pageSize
}: {
  page: number
  pageSize: number
}): UseQueryResult<any> {
  return useQuery([QueryKey.cargos, { page, pageSize }], getCargosList, {
    keepPreviousData: true,
    suspense: true
  })
}

export function useCargoQueryInfinite(
  dataQuery: any
): UseInfiniteQueryResult<IPagination<ICargo[]>> {
  return useInfiniteQuery([QueryKey.cargos, { dataQuery }], getCargosInfinite, {
    getNextPageParam: (lastPage: any) => {
      if (lastPage?.hasNextPage) {
        return lastPage?.page + 1
      }
    },
    cacheTime: Infinity
  })
}

export function useGetCargo(cargoId: string): UseQueryResult<ICargo> {
  return useQuery([QueryKey.cargos, { cargoId }], getCargo, {
    enabled: Boolean(cargoId)
  })
}

export interface IPedido {
  orderNumber: number
  orderDate: Date
  order: number
  deliveryDate: Date
  status: string
  totalItems: number
  deliveryAddress: string
}
