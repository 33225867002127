import { ISearchItems } from '@/interfaces/common'

export const LIST_OPTIONS: ISearchItems[] = [
  {
    keywords: 'Acompanhar Pedidos',
    uri: '/DemandsV3/Index?RedirectToTab=demandsTab',
    title: 'Acompanhar Pedidos meus consultar'
  },
  {
    keywords: 'Resumo de Pedido',
    uri: '/DemandsV3/Index',
    title: 'Acompanhar Pedidos'
  },
  {
    keywords: 'Exportar Pedido',
    uri: '/DemandsV3/Index',
    title: 'Pendências de  Pedidos'
  },
  {
    keywords: 'Detalhes de Pedido',
    uri: '/DemandsV3/Index',
    title: 'Acompanhar Pedidos'
  },
  {
    keywords: 'Criar Pedido',
    uri: '/DemandsV3/Index?RedirectToTab=createDemandsTab',
    title: 'Criar Pedidos transporte'
  },
  {
    keywords: 'Conta Corrente',
    uri: '/DemandsV3/Index',
    title: 'Conta Corrente Lançamento débito crédito'
  },
  {
    keywords: 'Devoluções',
    uri: '/DemandsV3/Index?RedirectToTab=devolutionTab',
    title: 'Devoluções fatura beneficiario reprocessar credito descontar'
  },
  {
    keywords: 'Configurações da Gestão de Saldo',
    uri: '/DemandsV3/Index?RedirectToTab=balanceTab',
    title: 'Gestão de Saldo escala trabalho'
  },
  {
    keywords: 'Importar Arquivo',
    uri: '/ToolboxV3/Index?RedirectToTab=DemandImportTab',
    title: 'Importar Arquivo'
  },
  {
    keywords: 'Exportar Arquivo',
    uri: '/ToolboxV3/Index?RedirectToTab=DemandsExportTab',
    title: 'Exportar Arquivo'
  },
  {
    keywords: 'De/Para Condução',
    uri: '/ToolboxV3/Index',
    title: 'Importar Arquivo'
  },
  {
    keywords: 'Histórico Arquivos Enviados',
    uri: '/ToolboxV3/Index',
    title: 'Importar Arquivo'
  },
  {
    keywords: 'Criar Solicitação',
    uri: '/Services/Index?RedirectToTab=DemandsExportTab',
    title: 'Criação solicitação fazer reclamação suporte ao site dúvidas'
  },
  {
    keywords: 'Acompanhar Solicitação',
    uri: '/Services/Index?RedirectToTab=mySolicitationsTab',
    title: 'Minhas Solicitações'
  },
  {
    keywords: 'Importar Solicitação',
    uri: '/Services/Index?RedirectToTab=importationTab',
    title: 'Importação'
  },
  {
    keywords: 'Arquivos de Solicitações Importadas',
    uri: '/Services/Index?RedirectToTab=importedFilesTab',
    title: 'Arquivos de Solicitações Importadas'
  },
  {
    keywords: 'Nova Roteirização Unitária',
    uri: '/RoutingRequest/Index?isVrPortal=True',
    title: 'Nova Roteirização Unitária'
  },
  {
    keywords: 'Roteirização por Importação',
    uri: '/RoutingRequest/Index?isVrPortal=True',
    title: 'Roteirização por Importação'
  },
  {
    keywords: 'Minhas Roteirizações',
    uri: '/RoutingRequest/Index?isVrPortal=True',
    title: 'Minhas Roteirizações'
  },
  {
    keywords: 'Relatório de Centro de Custo',
    uri: '/ReportsV3/Index?RedirectToTab=financialTab',
    title: 'Gerencial'
  },
  {
    keywords: 'Relatório de Encomendas',
    uri: '/ReportsV3/Index?RedirectToTab=managementlTab',
    title: 'Gerencial'
  },
  {
    keywords: 'Relatório de Gestão de Saldo',
    uri: '/ReportsV3/Index?RedirectToTab=financialTab',
    title: 'Gerencial'
  },
  {
    keywords: 'Relatório de Lista de Crédito',
    uri: '/ReportsV3/Index?RedirectToTab=managementlTab',
    title: 'Gerencial'
  },
  {
    keywords: 'Relatório de Pedidos de Venda',
    uri: '/ReportsV3/Index?RedirectToTab=managementlTab',
    title: 'Gerencial'
  },
  {
    keywords: 'Relatório Valor Importado x Valor Pedido de Venda',
    uri: '/ReportsV3/Index?RedirectToTab=managementlTab',
    title: 'Gerencial'
  },
  {
    keywords: 'Relatório Gerencial',
    uri: '/ReportsV3/Index?RedirectToTab=managementlTab',
    title: 'Gerencial'
  },
  {
    keywords: 'Relatório de Prévia de Compra',
    uri: '/ReportsV3/Inde?RedirectToTab=managementlTab',
    title: 'Cadastro'
  },
  {
    keywords: 'Relatório Financeiro',
    uri: '/ReportsV3/Index?RedirectToTab=financialTab',
    title: 'Financeiro'
  },
  {
    keywords: 'Imprimir Boletos em Aberto',
    uri: '/ReportsV3/Index?RedirectToTab=financialTab',
    title: 'Financeiro'
  },
  {
    keywords: 'Imprimir Notas Fiscais do Grupo',
    uri: '/ReportsV3/Index?RedirectToTab=financialTab',
    title: 'Financeiro'
  },
  {
    keywords: 'Imprimir Recibos',
    uri: '/ReportsV3/Index?RedirectToTab=financialTab',
    title: 'Financeiro'
  }
]
