import { Fragment, Suspense, useState } from 'react'

import { Menu, Transition } from '@headlessui/react'
import { EllipsisVerticalIcon } from '@heroicons/react/24/outline'
import classNames from 'classnames'

import MoveCompanyModal from '../move-company-modal'

const CustomActionsMenu = () => {
  const [isContactModalOpen, setContactModalOpen] = useState(false)
  const handleContactModalOpen = () => {
    setContactModalOpen(true)
  }
  return (
    <Menu as='div' className='relative'>
      <Suspense>
        <div>
          <Menu.Button className='flex text-sm '>
            <EllipsisVerticalIcon
              width={24}
              height={24}
              className='text-gray-600'
            />
          </Menu.Button>
        </div>
      </Suspense>
      <Transition
        as={Fragment}
        enter='transition ease-out duration-100'
        enterFrom='transform opacity-0 scale-95'
        enterTo='transform opacity-100 scale-100'
        leave='transition ease-in duration-75'
        leaveFrom='transform opacity-100 scale-100'
        leaveTo='transform opacity-0 scale-95'
      >
        <Menu.Items className='absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none'>
          <Menu.Item>
            {({ active }) => (
              <span
                className={classNames(
                  active ? 'bg-gray-100' : '',
                  'text-gray-700 block px-4 py-2 text-sm cursor-pointer'
                )}
              >
                Adicionar Benefício
              </span>
            )}
          </Menu.Item>
          <Menu.Item>
            {({ active }) => (
              <span
                className={classNames(
                  active ? 'bg-gray-100' : '',
                  'text-gray-700 block px-4 py-2 text-sm cursor-pointer'
                )}
              >
                Histórico
              </span>
            )}
          </Menu.Item>
          <Menu.Item>
            {({ active }) => (
              <span
                onClick={handleContactModalOpen}
                className={classNames(
                  active ? 'bg-gray-100' : '',
                  'text-gray-700 block px-4 py-2 text-sm cursor-pointer'
                )}
              >
                Trocar empresa
              </span>
            )}
          </Menu.Item>
          <Menu.Item>
            {({ active }) => (
              <span
                className={classNames(
                  active ? 'bg-gray-100' : '',
                  'text-gray-700 block px-4 py-2 text-sm cursor-pointer'
                )}
              >
                Opções de VT
              </span>
            )}
          </Menu.Item>
        </Menu.Items>
      </Transition>
      <MoveCompanyModal
        onClose={() => setContactModalOpen(false)}
        isOpen={isContactModalOpen}
      />
    </Menu>
  )
}

export default CustomActionsMenu
