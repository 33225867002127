import {
  UseInfiniteQueryResult,
  UseQueryResult,
  useInfiniteQuery,
  useQuery
} from 'react-query'

import { QueryKey } from '../../configs/queryKeys'
import { IDepartamento } from '../../interfaces/departamentos'
import {
  getDepartamento,
  getDepartamentos,
  getDepartamentosInfinite,
  getDepartamentosList
} from '../../services/departamentos'
import { IPagination } from '@/interfaces/pagination'

export function useGetDepartamentosData({
  page,
  pageSize
}: {
  page: number
  pageSize: number
}): UseQueryResult<any> {
  return useQuery(
    [QueryKey.departamentos, { page, pageSize }],
    getDepartamentos,
    {
      staleTime: 1000 * 60 * 60 * 24, // 1 day,
      keepPreviousData: true,
      cacheTime: 10 * (60 * 1000), // 10 mins
      retry: false,
      refetchOnReconnect: false,
      suspense: true
    }
  )
}

export function useGetDepartamentosList({
  page,
  pageSize
}: {
  page: number
  pageSize: number
}): UseQueryResult<any> {
  return useQuery(
    [QueryKey.departamentos, { page, pageSize }],
    getDepartamentosList,
    {
      keepPreviousData: true,
      suspense: true
    }
  )
}

export function useGetDepartamento(
  departamentoId: string
): UseQueryResult<IDepartamento> {
  return useQuery(
    [QueryKey.departamentos, { departamentoId }],
    getDepartamento,
    {
      enabled: Boolean(departamentoId)
    }
  )
}

//TODO: improve pagination based on props
export function useDepartamentotQueryInfinite(
  dataQuery: any
): UseInfiniteQueryResult<IPagination<IDepartamento[]>> {
  return useInfiniteQuery(
    [QueryKey.departamentos, { dataQuery }],
    getDepartamentosInfinite,
    {
      getNextPageParam: (lastPage: any) => {
        if (lastPage?.hasNextPage) {
          return lastPage?.page + 1
        }
      },
      cacheTime: Infinity
    }
  )
}
