import React from 'react'

import { ArrowsRightLeftIcon } from '@heroicons/react/24/solid'
import { Button, Modal } from 'flowbite-react'
import { useFormik } from 'formik'
import { useParams } from 'react-router-dom'
import * as Yup from 'yup'

import CustomSelect from '../basic/select/select.component'
import toastService from '../toast/toast-service'
import { useGetAddress } from '@/hooks/queries/use-get-address'
import { useGetBranchesData } from '@/hooks/queries/use-get-branch-data'
import { useGetCargo } from '@/hooks/queries/use-get-cargos-data'
import { useGetDepartamento } from '@/hooks/queries/use-get-departamentos-data'

interface MoveCompanyModalProps {
  onClose: () => void
  isOpen: boolean
}

const MoveCompanyModal: React.FC<MoveCompanyModalProps> = ({
  onClose,
  isOpen
}: MoveCompanyModalProps) => {
  const { data: branches } = useGetBranchesData()
  const { id } = useParams()
  const { data: cargo } = useGetCargo(id ?? '')
  const { data: departamento } = useGetDepartamento(id ?? '')
  const { data: endereco } = useGetAddress(id ?? '')

  const validationSchema = Yup.object().shape({
    empresa: Yup.string().required('Empresa é obrigatório'),
    cargo: Yup.string().required('Cargo é obrigatório'),
    departamento: Yup.string().required('Departamento é obrigatório'),
    periodo: Yup.string().required('Período é obrigatório'),
    endereco: Yup.string().required('Endereço é obrigatório')
  })

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      empresa: '',
      cargo: '',
      departamento: '',
      periodo: '',
      endereco: ''
    },
    validationSchema,
    onSubmit: async () => {
      toastService.success('Alterado com sucesso!')
    }
  })

  const empresasOptions = [
    {
      label: 'Empresa 1',
      value: '111'
    },
    {
      label: 'Empresa 2',
      value: '2222'
    }
  ]

  const cargosOptions = [
    {
      label: 'Cargo II',
      value: '111'
    },
    {
      label: 'Cargo III',
      value: '2222'
    }
  ]

  const departamentosOptions = [
    {
      label: 'Departamento II',
      value: '111'
    },
    {
      label: 'Departamento III',
      value: '2222'
    }
  ]

  const periodosOptions = [
    {
      label: 'Período II',
      value: '111'
    },
    {
      label: 'Período III',
      value: '2222'
    }
  ]

  const enderecosOptions = [
    {
      label: 'Endereço II',
      value: '111'
    },
    {
      label: 'Endereço III',
      value: '2222'
    }
  ]

  const handleCloseModal = () => {
    formik.resetForm()
    onClose()
  }
  return (
    <div>
      <Modal
        popup
        show={isOpen}
        onClose={handleCloseModal}
        className='w-full flex mx-auto'
        size='6xl'
      >
        <Modal.Header className='p-6'>
          <p className='font-bold'>Mover usuário de Empresa</p>
          <p className='font-semibold pb-3 text-sm md:text-lg text-center justify-center mx-auto'>
            Escolha para qual empresa o(a) usuário(a) será movido
          </p>
          <p className='pb-3 text-xs'>*Todos os campos são obrigatórios</p>
        </Modal.Header>
        <div className='hidden justify-evenly md:flex pb-3'>
          <p className='justify-center text-center'>De</p>
          <ArrowsRightLeftIcon width={20} height={20} className='ml-3' />
          <p className='justify-center text-center'>Para</p>
        </div>
        <form
          className='overflow-scroll py-3 2xl:overflow-hidden md:overflow-x-hidden'
          onSubmit={formik.handleSubmit}
        >
          <Modal.Body className='grid grid-cols-12 rounded-xl w-full gap-5 md:gap-0 2xl:overflow-hidden'>
            <div className='col-span-12 md:col-span-6 text-gray-700 font-semibold text-sm md:border-r border-gray-400'>
              <p className='mt-2 md:mt-2'>Empresa:</p>
              <p className=' text-gray-500'>{branches?.[0].name}</p>
              <p className='mt-2 md:mt-10'>Cargo:</p>
              <p className=' text-gray-500'>{cargo?.name} Desenvolvedor</p>
              <p className='mt-2 md:mt-10'>Departamento:</p>
              <p className=' text-gray-500'>{departamento?.description} T.I</p>
              <p className='mt-2 md:mt-10'>Período:</p>
              <p className=' text-gray-500'>21</p>
              <p className='mt-2 md:mt-11'>Endereço:</p>
              <p className=' text-gray-500'>{endereco?.street} Londrina</p>
            </div>
            <div className='col-span-12 md:col-span-6  md:pl-5 text-gray-500'>
              <div className='text-left pb-4'>
                <CustomSelect
                  label={'Empresa'}
                  formik={formik}
                  options={empresasOptions}
                  placeholder='Selecione uma empresa'
                  field='empresa'
                />
              </div>

              <div className='text-left pb-4'>
                <CustomSelect
                  label={'Cargo'}
                  formik={formik}
                  options={cargosOptions}
                  placeholder='Selecione um cargo'
                  field='cargo'
                />
              </div>

              <div className='text-left pb-4'>
                <CustomSelect
                  label={'Departamento'}
                  formik={formik}
                  options={departamentosOptions}
                  placeholder='Selecione um departamento'
                  field='departamento'
                />
              </div>

              <div className='text-left pb-4'>
                <CustomSelect
                  label={'Período'}
                  formik={formik}
                  options={periodosOptions}
                  placeholder='Selecione um período'
                  field='periodo'
                />
              </div>

              <div className='text-left pb-4'>
                <CustomSelect
                  label={'Endereço'}
                  formik={formik}
                  options={enderecosOptions}
                  placeholder='Selecione um endereço'
                  field='endereco'
                />
              </div>
            </div>
          </Modal.Body>
          <p className='px-6 text-xs'>
            *Se o novo endereço for de região diferente do endereço atual, os
            benefícios não serão migrados
          </p>
          <Modal.Footer className='flex justify-end'>
            <Button onClick={handleCloseModal} color='gray'>
              Cancelar
            </Button>
            <Button className='bg-quaternary' type='submit'>
              Alterar
            </Button>
          </Modal.Footer>
        </form>
      </Modal>
    </div>
  )
}

export default MoveCompanyModal
