// import { Fragment } from 'react'

import { Navigate, Route, Routes, useLocation } from 'react-router-dom'

import { WithAuth } from './with-auth'
import { WithGuest } from './with-guest'
import { OnixRouteNames, routeNames } from '@/configs/routePaths'
import { useUserContext } from '@/contexts/user.context'
// import NotFound from '@/pages/404/not-found.page'
import ForgotPassword from '@/pages/account/forgot-password'
// import Cadastros from '@/pages/Cadastros'
// import Beneficiarios from '@/pages/Cadastros/beneficiarios'
// import BeneficiariosAdd from '@/pages/Cadastros/beneficiarios/add'
// import BeneficiariosEdit from '@/pages/Cadastros/beneficiarios/edit'
// import BeneficiariosView from '@/pages/Cadastros/beneficiarios/view'
// import Cargos from '@/pages/Cadastros/Cargos'
// import CargosAdd from '@/pages/Cadastros/Cargos/add'
// import CargosEdit from '@/pages/Cadastros/Cargos/edit'
// import CargosView from '@/pages/Cadastros/Cargos/view'
// import Departamentos from '@/pages/Cadastros/Departamentos'
// import DepartamentosAdd from '@/pages/Cadastros/Departamentos/add'
// import DepartamentosEdit from '@/pages/Cadastros/Departamentos/edit'
// import DepartamentosView from '@/pages/Cadastros/Departamentos/view'
// import LocaisDeEntrega from '@/pages/Cadastros/locais-de-entrega'
// import LocaisDeEntregaAdd from '@/pages/Cadastros/locais-de-entrega/add'
// import LocaisDeEntregaEdit from '@/pages/Cadastros/locais-de-entrega/edit'
// import LocaisDeEntregaView from '@/pages/Cadastros/locais-de-entrega/view'
import Dashboard from '@/pages/Dashboard'
import Encomendas from '@/pages/Encomendas'
import EncomendasEdit from '@/pages/Encomendas/edit'
import EncomendasView from '@/pages/Encomendas/view'
import Login from '@/pages/Login'
import Platform from '@/pages/Platform'
import SelectAccount from '@/pages/select-account'

export function Router() {
  const location = useLocation()
  const { isAuthenticated } = useUserContext()
  const hasVrIdAccessToken = location.search.includes('accessToken')

  return (
    <Routes>
      <Route
        path={routeNames.homeDashboard}
        element={<Navigate to={routeNames.dashboard} />}
      />
      <Route
        path={routeNames.root}
        element={
          <Navigate
            to={
              isAuthenticated && !hasVrIdAccessToken
                ? routeNames.homeDashboard
                : routeNames.login + location.search
            }
          />
        }
      />
      {/* Guest routes */}
      <Route path={routeNames.root} element={<WithGuest />}>
        <Route path={routeNames.login} element={<Login />} />
        <Route path={routeNames.CheckRecharge} element={<Platform />} />
        <Route path={routeNames.selectAccounts} element={<SelectAccount />} />
        <Route path={routeNames.forgotPassword} element={<Platform />} />
      </Route>
      {/* Auth routes */}
      <Route path={routeNames.root} element={<WithAuth />}>
        <Route
          path={`/${routeNames.encomendas}`}
          key={'Encomendas'}
          element={<Encomendas />}
        >
          <Route
            path={`/${routeNames.encomendas}`}
            element={<EncomendasView />}
          />
          <Route path={routeNames.edit} element={<EncomendasEdit />} />
        </Route>
        <Route path={routeNames.dashboard} element={<Dashboard />} />
        <Route path={routeNames.homeDashboard} element={<Platform />} />
        <Route path={routeNames.forgotPassword} element={<ForgotPassword />} />
        <Route path='*' element={<Platform />} />
        {/* <Route path={routeNames.registers} element={<Cadastros />}>
          <Route
            path={routeNames.departamentos}
            key={'Departamentos'}
            element={<Departamentos />}
          >
            <Route path={''} element={<DepartamentosView />} />
            <Route path={routeNames.add} element={<DepartamentosAdd />} />
            <Route path={routeNames.edit} element={<DepartamentosEdit />} />
          </Route>
          <Route path={routeNames.cargos} key={'Cargos'} element={<Cargos />}>
            <Route path={''} element={<CargosView />} />
            <Route path={routeNames.add} element={<CargosAdd />} />
            <Route path={routeNames.edit} element={<CargosEdit />} />
          </Route>
          <Route
            path={routeNames.beneficiarios}
            key={'Beneficiários'}
            element={<Beneficiarios />}
          >
            <Route path={''} element={<BeneficiariosView />} />
            <Route path={routeNames.add} element={<BeneficiariosAdd />} />
            <Route path={routeNames.edit} element={<BeneficiariosEdit />} />
          </Route>
          <Route
            path={routeNames.locaisDeEntrega}
            key={'Locais de Entrega'}
            element={<LocaisDeEntrega />}
          >
            <Route path={''} element={<LocaisDeEntregaView />} />
            <Route path={routeNames.add} element={<LocaisDeEntregaAdd />} />
            <Route path={routeNames.edit} element={<LocaisDeEntregaEdit />} />
          </Route>
          <Route
            path={routeNames.dadosDaEmpresa}
            key={'Dados da Empresa'}
            element={<Fragment />}
          >
            <Route path={''} element={<Fragment />} />
            <Route path={routeNames.add} element={<Fragment />} />
            <Route path={routeNames.edit} element={<Fragment />} />
          </Route>
        </Route> */}
        {OnixRouteNames.map(route => (
          <Route path={route} element={<Platform />} key={route} />
        ))}
      </Route>
    </Routes>
  )
}
