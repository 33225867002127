export function filterToolsBySentence(array: any, sentence: string) {
  return array?.filter((data: { keywords: string; title: string }) => {
    const { keywords, title } = data

    return JSON.stringify([keywords, title])
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '')
      .toLowerCase()
      .includes(
        sentence
          .normalize('NFD')
          .replace(/[\u0300-\u036f]/g, '')
          .toLowerCase()
      )
  })
}
