import React from 'react'

import * as Sentry from '@sentry/browser'
import ReactDOM from 'react-dom/client'

import App from './App.tsx'

if (process.env.NODE_ENV != 'development') {
  Sentry.init({
    environment: process.env.NODE_ENV,
    dsn: 'https://6f667463522da0e573eb40e92c1e3606@o4506435305078784.ingest.sentry.io/4506435307372544',
    integrations: [Sentry.breadcrumbsIntegration()],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0 // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  })
}

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
)
