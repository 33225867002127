import { CheckCircleIcon } from '@heroicons/react/24/outline'
import { XCircleIcon } from '@heroicons/react/24/solid'
import { toast } from 'react-toastify'

// import ToastAction, { IToastAction } from './ToastAction'

export interface IToastService {
  title?: string
  subtitle?: string
}

interface IContructorToast {
  success: (props: IToastService | string) => void
  error: (props: IToastService | string) => void
}

const toastService: IContructorToast = {} as IContructorToast

toastService.success = (props: IToastService | string) => {
  if (typeof props === 'object' && props !== null) {
    toast.success(props.title, {
      icon: <CheckCircleIcon color='green' />,
      autoClose: 2500,
      hideProgressBar: true,
      theme: 'light',
      toastId: 'popup-success'
    })
  } else {
    toast.success(props, {
      icon: <CheckCircleIcon color='green' />,
      autoClose: 2500,
      hideProgressBar: true,
      theme: 'light',
      toastId: 'popup-success'
    })
  }
}

toastService.error = (props: IToastService | string) => {
  if (typeof props === 'object' && props !== null) {
    toast.error(props.title, {
      icon: <XCircleIcon color='red' />,
      autoClose: 2500,
      hideProgressBar: true
    })
  } else {
    toast.error(props, {
      icon: <XCircleIcon color='red' />,
      autoClose: 2500,
      hideProgressBar: true
    })
  }
}

export default toastService
