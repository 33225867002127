import { useMutation } from 'react-query'
import { useNavigate } from 'react-router-dom'

import toastService from '@/components/toast/toast-service'
import { routeNames } from '@/configs/routePaths'
import { login } from '@/services/authentication'
import { TokenStorage } from '@/utils/token-storage'

export function useLoginQueryMutation() {
  const navigate = useNavigate()

  return useMutation(login, {
    onSuccess: async data => {
      TokenStorage.storeToken(data.token.token)
      navigate(routeNames.selectAccounts)
    },
    onError: async () => {
      toastService.error('Login/Senha inválidos.')
    }
  })
}
