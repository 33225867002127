import { Suspense } from 'react'

import Loader from '@/components/Loader'
import SelectAccountContainer from '@/containers/select-account.container'

const SelectAccount = () => {
  return (
    <Suspense fallback={<Loader size='lg' />}>
      <SelectAccountContainer />
    </Suspense>
  )
}

export default SelectAccount
