import { Button, Modal } from 'flowbite-react'
import { HiOutlineExclamationCircle } from 'react-icons/hi'
interface DeleteModalProps {
  isOpen: boolean | false
  onClose: any
  onDelete: () => void
}
export default function DeleteModal({
  isOpen,
  onClose,
  onDelete
}: DeleteModalProps) {
  const handleDelete = () => {
    onDelete()
    onClose()
  }

  return isOpen ? (
    <>
      <Modal show={isOpen} size='md' onClose={onClose} popup>
        <Modal.Header />
        <Modal.Body>
          <div className='text-center'>
            <HiOutlineExclamationCircle className='mx-auto mb-4 h-14 w-14 text-red-700' />
            <h3 className='mb-5 text-lg font-normal text-gray-500'>
              Deseja excluir esse item?
            </h3>
            <div className='flex justify-center gap-4'>
              <Button color='failure' onClick={handleDelete}>
                {'Excluir'}
              </Button>
              <Button color='gray' onClick={onClose}>
                Cancelar
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  ) : (
    <></>
  )
}
