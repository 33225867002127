import { Flowbite } from 'flowbite-react'
import { QueryClient, QueryClientProvider } from 'react-query'
import { BrowserRouter } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'

import { PlatformProvider } from './contexts/platform.context'
import { UserProvider } from './contexts/user.context'
import { Router } from './routes/index.routes'
import { customTheme } from './styles/theme'
import './styles/global.css'
import 'react-toastify/dist/ReactToastify.css'

function App() {
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false
      }
    }
  })

  return (
    <QueryClientProvider client={queryClient}>
      <Flowbite theme={{ theme: customTheme }}>
        <BrowserRouter>
          <UserProvider>
            <PlatformProvider>
              <div className='h-screen bg-gray-50 flex flex-col'>
                <Router />
              </div>
            </PlatformProvider>
          </UserProvider>
        </BrowserRouter>
      </Flowbite>
      <ToastContainer
        position='top-right'
        autoClose={2500}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        draggable
        theme='light'
      />
    </QueryClientProvider>
  )
}

export default App
