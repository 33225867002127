import { Label, TextInput } from 'flowbite-react'
import { FormikValues } from 'formik'

interface CustomSelectProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  formik: FormikValues
  label: string
  field: string
  placeholder?: string
  mask?: (value: string) => string
}

const CustomTextInput = ({
  formik,
  field,
  placeholder,
  label,
  type,
  mask,
  ...props
}: CustomSelectProps) => {
  return (
    <>
      {label && <Label>{label}</Label>}
      <TextInput
        id={field}
        type={type}
        placeholder={placeholder}
        color={formik.touched[field] && formik.errors[field] ? 'failure' : ''}
        helperText={formik.touched[field] && formik.errors[field]}
        {...formik.getFieldProps(field)}
        {...props}
        value={mask ? mask(formik.values[field]) : formik.values[field]}
      />
    </>
  )
}

export default CustomTextInput
