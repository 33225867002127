export const endPoints = {
  accounts: '/User/Accounts',
  branches: '/User/Branches',
  notifitionItems: '/VersionNews/CountOfNews',
  userData: '/User/Profile',
  auth: '/Auth/User',
  vrIdUser: '/Auth/VrIdUser',
  userAccount: '/Auth/UserAccount',
  userBranch: '/Auth/UserBranch',
  userAllowed: '/User/AllowedToPortalNewVersion',
  cargos: '/JobRoles',
  cargosList: '/JobRoles/WithActiveEmployeesAndFares',
  departamentos: '/Departments',
  departamentosList: '/Departments/WithActiveEmployeesAndFares',
  locaisDeEntrega: '/WorkStations',
  locaisDeEntregaActive: '/WorkStations/WithActiveEmployees',
  beneficiarios: '',
  beneficiariosList: '',
  cep: '/Address',
  news: '/VersionNews/HighlightedNews',
  blip: '/BlipChat/UserCredentials',
  authFromMicrosservice: '/ApiAuth/AuthenticateFromMicroservice',
  logoutExternal: '/Authentication/LogoutExternal',
  loginWithMicroserviceToken: '/ApiAuth/LoginWithMicroserviceToken',
  logisticList: '/LogisticsDetails',
  logistic: '/Logistics',
  logisticItem: '/LogisticsDetails',
  logisticStatusList: '/Logistics/Status'
}
