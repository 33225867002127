import { useEffect, useState } from 'react'

import axios from 'axios'
import Cookie from 'js-cookie'
import { useQueryClient } from 'react-query'
import { useNavigate } from 'react-router-dom'

import toastService from '@/components/toast/toast-service'
import Constants from '@/configs/constants'
import { endPoints } from '@/configs/endPoints'
import { OnixRouteNames, routeNames } from '@/configs/routePaths'
import { getBranchUserAccount } from '@/services/branches'
import { createContext } from '@/utils/create-context'
import { API_URL } from '@/utils/fetcher'
import { TokenStorage } from '@/utils/token-storage'
interface IContextProps {
  logOut: () => void
  isAuthenticated: boolean
  legacyAuth: (token: string) => void
  vrIdAuth: (token: string) => void
  isLoadingAuth: boolean
  isLoadingRedirect: boolean
  handleSelectAccount: (selectedBranch: string, allowedToPortalNewVersion: boolean) => void
  handleRedirectDashboard: (token: string) => void
  handleRedictAfterLogin: (token: string) => void
}

interface IProviderProps {
  children: JSX.Element
}

const [useUserContext, UserContext] = createContext<IContextProps>()

const UserProvider = ({ children }: IProviderProps) => {
  const isAuthenticated = Boolean(Cookie.get(Constants.TOKEN_STORAGE_KEY))
  const AppUrl = process.env.VITE_APP_ONIX_URL ?? ''
  const [isLoadingAuth, setIsLoadingAuth] = useState<boolean>(false)
  const queryClient = useQueryClient()
  const [isAuth, setIsAuth] = useState(false)
  const [isLogOut, setIsLogOut] = useState(false)
  const [token, setToken] = useState('')
  const [isLoadingRedirect, setIsLoadingRedirect] = useState<boolean>(false)

  useEffect(() => {
    const isInV1Experience = OnixRouteNames.includes(location.pathname)
    const blip = document.getElementById(Constants.BLIP_REF)
    if (blip != null) {
      blip.className = isInV1Experience ? 'hidden' : 'block'
    }
  }, [location.pathname])

  const navigate = useNavigate()

  const logOut = () => {
    setIsLogOut(true)
    setIsAuth(true)
    setTimeout(() => {
      setIsAuth(false)
      setIsLogOut(false)
      navigate(routeNames.root)
    }, 1000)
    TokenStorage.logout()
    queryClient.clear()
  }

  const legacyAuth = async (token: string | null) => {
    if (token) {
      setIsLoadingAuth(true)
      await axios
        .get(API_URL + endPoints.accounts, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        })
        .then(() => {
          toastService.success('Login realizado!')
          TokenStorage.storeToken(token)
          navigate(routeNames.selectAccounts)
        })
        .catch(error => {
          const messsage =
            error?.response?.data?.userMessage ?? Constants.ERROR_DATA
          toastService.error(messsage)
        })
        .finally(() => {
          setIsLoadingAuth(false)
        })
    }
    return
  }

  const vrIdAuth = async (token: string | null) => {
    if (token) {
      setIsLoadingAuth(true)
      await axios
        .post(API_URL + endPoints.vrIdUser, { AccessToken: token })
        .then((data: any) => data.data)
        .then((data: any) => {
          toastService.success('Login realizado!')
          TokenStorage.storeToken(data.token.token)
          navigate(routeNames.selectAccounts)
        })
        .catch(error => {
          console.log(error)
          const messsage =
            error?.response?.data?.userMessage ?? Constants.ERROR_DATA
          toastService.error(messsage)
        })
        .finally(() => {
          setIsLoadingAuth(false)
        })
    }
    return
  }

  const handleRedictAfterLogin = (token: string) => {
    setIsLoadingRedirect(true)
    location.replace(
      `${AppUrl}${endPoints.loginWithMicroserviceToken}?token=${token}`
    )
  }

  const handleSelectAccount = async (
    selectedBranch: string,
  ) => {
    setIsLoadingRedirect(true)
    try {
      const data = await getBranchUserAccount(selectedBranch ?? '')
      if (data.allowedToPortalNewVersion) {
        TokenStorage.storeToken(data.token.token)
        setToken(data.token.token)
        setIsAuth(true)
        setIsLoadingRedirect(false)
      }
      setTimeout(() => {
        if (!data.allowedToPortalNewVersion) {
          TokenStorage.logout()
          handleRedictAfterLogin(data.token.token)
        } else {
          navigate(routeNames.dashboard)
        }
      }, 1500)
    } catch (error) {
      console.log(error)
      setIsLoadingRedirect(false)
    }
  }

  const handleRedirectDashboard = (token: string) => {
    if(token){
      setIsLoadingAuth(true)
      setToken(token)
      setIsAuth(true)
      TokenStorage.storeToken(token)
      setTimeout(() => {
        toastService.success('Login realizado!')
        navigate(routeNames.dashboard)
        setIsLoadingAuth(false)
      }, 1500)
    }
  }


  return (
    <>
      <UserContext
        value={{
          isLoadingRedirect,
          logOut,
          isAuthenticated,
          legacyAuth,
          vrIdAuth,
          isLoadingAuth,
          handleSelectAccount,
          handleRedirectDashboard,
          handleRedictAfterLogin
        }}
      >
        {children}
      </UserContext>
      {isAuth && !isLogOut && (
        <iframe
          width={50}
          height={50}
          className='absolute top-0 opacity-0 pointer-events-none overflow-hidden z-[-1]'
          src={AppUrl + `${endPoints.authFromMicrosservice}?token=` + token}
        />
      )}
      {isAuth && isLogOut && (
        <iframe
          width={50}
          height={50}
          className='absolute top-0 opacity-0 pointer-events-none overflow-hidden z-[-1]'
          src={AppUrl + endPoints.logoutExternal}
        />
      )}
    </>
  )
}

export { UserProvider, useUserContext }
