import { useEffect, useState } from 'react'

import { EyeIcon, EyeSlashIcon } from '@heroicons/react/20/solid'
import { Button, Label, TextInput } from 'flowbite-react'
import { useFormik } from 'formik'
import { Link, useNavigate, useSearchParams } from 'react-router-dom'
import * as Yup from 'yup'

import { routeNames } from '../../configs/routePaths'
import { useUserContext } from '../../contexts/user.context'
import { useLoginQueryMutation } from '../../hooks/mutations/use-login-mutation'
import Loader from '@/components/Loader'
import { usePlataformContext } from '@/contexts/platform.context'

const Login = () => {
  const navigate = useNavigate()
  const { handleRedirectDashboard, vrIdAuth, isLoadingAuth } = useUserContext()
  const [searchParams] = useSearchParams()
  const [isVisible, setIsVisible] = useState<boolean>(false)
  const { mutate, isLoading } = useLoginQueryMutation()
  const { sendMessage } = usePlataformContext()
  const validationSchema = Yup.object().shape({
    username: Yup.string()
      .required('Campo inválido')
      .required('Este campo é obrigatório'),
    password: Yup.string().required('Senha obrigatória')
  })

  const formik = useFormik({
    initialValues: {
      username: '',
      password: ''
    },
    validationSchema,
    onSubmit: async values => {
      await mutate(values)
    }
  })

  useEffect(() => {
    sendMessage({ action: 'LOGOUT' })
    const token = searchParams.get('onixPortalIntegration')
    const vrIdIntegration = searchParams.get('accessToken')
    vrIdAuth(vrIdIntegration ?? '')
    handleRedirectDashboard(token ?? '')
  }, [])

  return isLoadingAuth ? (
    <Loader size='lg' />
  ) : (
    <main className='flex flex-1 flex-col main__login'>
      <div className='flex flex-row justify-center items-center md:p-10 h-full z-10 gap-10 container mx-auto'>
        <div className='h-full bg-[url("/images/main_bg_home.png")] bg-no-repeat bg-center bg-contain flex-1 hidden md:block'></div>
        <div className='flex-[0.9] flex items-center justify-center'>
          <form
            className='max-w-md flex-1 border rounded-md p-5 border-gray-200 shadow-md bg-white'
            onSubmit={formik.handleSubmit}
          >
            <h1 className=' font-semibold text-2xl mb-5'>Login</h1>
            <div>
              <div className='mb-2 block'>
                <Label htmlFor='username' value='E-mail / CPF' />
              </div>
              <TextInput
                id='username'
                type='text'
                placeholder='Digite seu e-mail ou CPF'
                color={formik.errors.username ? 'failure' : ''}
                helperText={formik.errors.username}
                {...formik.getFieldProps('username')}
              />
            </div>
            <div className='relative'>
              <div className='mb-2 block'>
                <Label htmlFor='password' value='Senha' />
              </div>
              <TextInput
                id='password'
                type={isVisible ? 'text' : 'password'}
                color={formik.errors.password ? 'failure' : ''}
                helperText={formik.errors.password}
                {...formik.getFieldProps('password')}
              />
              <div className='absolute right-3 top-[2.8rem] cursor-pointer z-20'>
                {isVisible ? (
                  <EyeSlashIcon
                    width={15}
                    height={15}
                    color='black'
                    onClick={() => setIsVisible(false)}
                  />
                ) : (
                  <EyeIcon
                    width={15}
                    height={15}
                    color='black'
                    onClick={() => setIsVisible(true)}
                  />
                )}
              </div>
            </div>
            <div className='flex flex-1 justify-between items-center'>
              <Link to={routeNames.forgotPassword} className='text-sm'>
                Esqueceu sua senha ?
              </Link>
            </div>
            <div className='flex flex-1 mb-5 justify-between items-center pt-5'>
              <Button
                type='submit'
                color='success'
                className='bg-quaternary'
                isProcessing={isLoading}
                disabled={isLoading}
              >
                Fazer login
              </Button>
              <Button
                type='button'
                color='dark'
                onClick={() => navigate('/CheckRecharge/CheckCpf')}
              >
                Consultar recargas
              </Button>
            </div>
            <div className='w-100 flex justify-center items-center'>
              <Link
                to={'https://trabalhador.vrtransporte.com.br/'}
                target='_blank'
              >
                <span className=' font-semibold text-center text-gray-700 underline text-sm cursor-pointer'>
                  Acesso ao portal trabalhador
                </span>
              </Link>
            </div>
          </form>
        </div>
      </div>
      <div className='curve_bg'></div>
    </main>
  )
}

export default Login
