import { endPoints } from '@/configs/endPoints'
import { Branch, IUserAccount } from '@/interfaces/branches'
import { API } from '@/utils/fetcher'

export async function getBranches(): Promise<Branch[]> {
  const data = await API.get<Branch[]>(endPoints.branches)

  return data
}

// User Accounts
export async function getBranchUserAccount(
  accountId: string
): Promise<IUserAccount> {
  const data = await API.post<Branch[]>(endPoints.userAccount, { accountId })

  return data
}

export async function setUserBranch(branchId: string): Promise<IUserAccount> {
  const data = await API.post(endPoints.userBranch, { branchId })

  return data
}

export async function getNotificationItems(): Promise<any> {
  const data = await API.get<any>(endPoints.notifitionItems)

  return data
}
