import { Key, useMemo, useRef, useState } from 'react'

import { Transition } from '@headlessui/react'
import { MagnifyingGlassIcon } from '@heroicons/react/24/outline'
import { TextInput } from 'flowbite-react'
import { useNavigate } from 'react-router-dom'

import { LIST_OPTIONS } from './search-options'
import { ISearchItems } from '@/interfaces/common'
import { filterToolsBySentence } from '@/utils/filterBySentence'

const SearchList = () => {
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [searchValue, setSearchValue] = useState<string>('')
  const navigate = useNavigate()
  const formRef = useRef<HTMLFormElement>(null)
  const toolsListFiltered = useMemo(
    () => filterToolsBySentence(LIST_OPTIONS, searchValue),
    [searchValue, LIST_OPTIONS]
  )
  const highlightSearchResults = (text: string, searchValue: string) => {
    const regex = new RegExp(`(${searchValue})`, 'gi')
    return text.split(regex).map((part: any, index: Key | null | undefined) =>
      regex.test(part) ? (
        <span className='text-gray-800 font-bold' key={index}>
          {part}
        </span>
      ) : (
        part
      )
    )
  }

  const displayResults =
    isOpen && searchValue.length > 2 && toolsListFiltered.length > 0
  const handleItemSelection = (item: ISearchItems) => {
    navigate(item.uri)
    setIsOpen(false)
    setSearchValue('')
    if (formRef.current) {
      formRef.current.reset()
    }
  }
  const handleFocusInput = () => {
    setIsOpen(true)
  }

  const handleBlurInput = () => {
    setTimeout(() => {
      setIsOpen(false)
    }, 200)
  }
  const handleSearchValue = (event: React.UIEvent<HTMLInputElement> | any) => {
    setSearchValue(event.target.value)
  }

  return (
    <div className='relative w-[18rem] md:w-full'>
      <form ref={formRef}>
        <TextInput
          type='text'
          id='busca-vr'
          autoComplete='off'
          color='gray'
          onKeyUp={handleSearchValue}
          onFocus={handleFocusInput}
          onBlur={handleBlurInput}
          theme={{
            field: {
              input: {
                colors: {
                  gray: 'focus:outline-none h-[38px] text-sm focus:shadow-none w-full focus:ring-0'
                }
              }
            }
          }}
          placeholder='Buscar'
        />
        <MagnifyingGlassIcon className='absolute top-0 end-0 h-full p-2 font-medium border-black rounded-lg stroke-2 cursor-pointer bg-[#FFF110] focus:border-0 focus:outline-none' />
      </form>
      <Transition
        as={'div'}
        show={displayResults}
        enter='transition ease-out duration-100'
        enterFrom='transform opacity-0 scale-95'
        enterTo='transform opacity-100 scale-100'
        leave='transition ease-in duration-75'
        leaveFrom='transform opacity-100 scale-100'
        leaveTo='transform opacity-0 scale-95'
        className={`absolute left-0 max-h-[42rem] overflow-hidden top-12 p-5 bg-white shadow w-full h-auto z-30 rounded`}
      >
        <ul className='gap-3 grid text-sm font-normal'>
          {toolsListFiltered.map((item: ISearchItems) => (
            <span
              className='cursor-pointer leading-5 text-sm text-gray-600'
              key={item.keywords}
              onClick={() => handleItemSelection(item)}
            >
              {highlightSearchResults(item.keywords, searchValue)}
            </span>
          ))}
        </ul>
      </Transition>
    </div>
  )
}

export default SearchList
