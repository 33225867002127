export function formatDateString(dateString: string): string {
  const date = new Date(dateString)

  // Extract parts of the date
  const day = String(date.getUTCDate()).padStart(2, '0')
  const month = String(date.getUTCMonth() + 1).padStart(2, '0') // Months are zero-based
  const year = date.getUTCFullYear()

  const hours = String(date.getUTCHours()).padStart(2, '0')
  const minutes = String(date.getUTCMinutes()).padStart(2, '0')
  const seconds = String(date.getUTCSeconds()).padStart(2, '0')

  // Format the date
  const formattedDate = `${day}/${month}/${year}`

  // Check if the time is exactly 00:00:00
  if (hours === '00' && minutes === '00' && seconds === '00') {
    return formattedDate
  }

  // Format the time
  const formattedTime = `${hours}:${minutes}:${seconds}`

  // Return the combined date and time
  return `${formattedDate} - ${formattedTime}`
}
