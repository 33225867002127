import { Disclosure } from '@headlessui/react'
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline'
import { Link } from 'react-router-dom'

import { routeNames } from '@/configs/routePaths'

const navigation = [
  { name: 'Painel', href: '/dashboard', current: true },
  {
    name: 'Cadastros',
    href: `${routeNames.registers}`,
    current: false
  },
  { name: 'Pedidos', href: '/DemandsV3/Index', current: false },
  { name: 'Importação', href: '/ToolboxV3/Index', current: false },
  { name: 'Logística', href: `/${routeNames.encomendas}`, current: false },
  {
    name: 'Base de Conhecimento',
    href: '/KnowledgeBaseV3/Index',
    current: false
  },
  { name: 'Solicitações', href: '/Services/Index', current: false },
  {
    name: 'Roteirizações',
    href: '/RoutingV3/Index?isVrPortal=True',
    current: false
  },
  { name: 'Relatórios', href: '/ReportsV3/Index', current: false }
]

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ')
}

export default function NavBar() {
  return (
    <Disclosure as='nav' className='bg-quintenary'>
      {({ open }) => (
        <>
          <div className='mx-auto max-w-full flex md:justify-center px-2 sm:px-6 lg:px-8'>
            <div className='relative flex h-16 items-center justify-between'>
              <div className='absolute inset-y-0 left-0 flex items-center sm:hidden'>
                {/* Mobile menu button */}
                <Disclosure.Button className='inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white'>
                  <span className='sr-only'>Open main menu</span>
                  {open ? (
                    <XMarkIcon
                      className='block h-6 w-6 text-primary-dark'
                      aria-hidden='true'
                    />
                  ) : (
                    <Bars3Icon
                      className='block h-6 w-6 text-primary-dark'
                      aria-hidden='true'
                    />
                  )}
                </Disclosure.Button>
              </div>
              <div className='flex flex-1 items-center justify-center sm:items-stretch sm:justify-start'>
                <div className='hidden sm:ml-6 sm:block'>
                  <div className='flex space-x-4'>
                    {navigation.map(item => (
                      <Link
                        key={item.name}
                        to={item.href}
                        className={classNames(
                          item.href.includes(location.pathname)
                            ? 'bg-[#00be28] text-white'
                            : 'text-gray-900 hover:bg-quaternary hover:text-white',
                          'rounded-md px-3 py-2 text-sm font-semibold transition-all'
                        )}
                        aria-current={
                          location.pathname === item.href ? 'page' : undefined
                        }
                      >
                        {item.name}
                      </Link>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <Disclosure.Panel className='sm:hidden'>
            <div className='space-y-1 px-2 pb-3 pt-2'>
              {navigation.map(item => (
                <Disclosure.Button
                  key={item.name}
                  as='a'
                  href={item.href}
                  className={classNames(
                    location.pathname === item.href
                      ? 'bg-[#00be28] text-white'
                      : 'text-gray-900 hover:bg-gray-600 hover:text-white',
                    'block rounded-md px-3 py-2 text-base font-medium'
                  )}
                  aria-current={
                    location.pathname === item.href ? 'page' : undefined
                  }
                >
                  {item.name}
                </Disclosure.Button>
              ))}
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  )
}
