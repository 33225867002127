import { CustomFlowbiteTheme } from 'flowbite-react'

export const customTheme: CustomFlowbiteTheme = {
  textInput: {
    field: {
      input: {
        base: 'block w-full border disabled:cursor-not-allowed disabled:opacity-50 rounded-lg p-2.5 text-sm border-gray-300 focus:border-white focus:shadow-none'
      }
    }
  },
  select: {
    field: {
      select: {
        base: 'block w-full border disabled:cursor-not-allowed disabled:opacity-50 rounded-lg p-2.5 text-sm border-gray-300 focus:border-white focus:shadow-none'
      }
    }
  }
}
