export enum QueryKey {
  profileUser = 'profileUser',
  news = 'news',
  allowedPortal = 'allowedPortal',
  profile = 'profile',
  dashboard = 'dashboard',
  departamentos = 'departamentos',
  locaisDeEntrega = 'locais-de-entrega',
  cargos = 'cargos',
  beneficiarios = 'beneficiarios',
  branches = 'branches',
  accounts = 'accounts',
  cep = 'cep',
  blip = 'blip',
  logisticList = 'logistic-list',
  logisticListItems = 'logistic-list-items',
  logistic = 'logistic',
  logisticStatusList = 'logistic-status-list'
}
