import { useState } from 'react'

import { Navigate, Outlet } from 'react-router-dom'

import GuestHeader from '@/components/structure/guest-header'
import { routeNames } from '@/configs/routePaths'

export function WithGuest() {
  const [isLogged] = useState<boolean>(false)
  if (isLogged) {
    return (
      <>
        <Navigate to={routeNames.homeDashboard} replace />
      </>
    )
  }

  return (
    <>
      <GuestHeader />
      <Outlet />
    </>
  )
}
