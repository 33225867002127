import { useState } from 'react'

import classNames from 'classnames'
import { Pagination } from 'flowbite-react'

interface IPaginationItemProps {
  totalPages: number
  totalItems?: number
  pageSize?: number
  onPageChange: (page: number) => void
}

const PaginationItem = ({
  totalPages,
  onPageChange,
  totalItems,
  pageSize
}: IPaginationItemProps) => {
  const [currentPage, setCurrentPage] = useState(1)
  const handlePageChange = (page: number) => {
    setCurrentPage(page)
    onPageChange(page)
  }
  return (
    <div className='flex justify-between items-center w-full'>
      {totalItems && totalPages > 2 ? (
        <span className='text-gray-800 text-sm text-normal'>
          Exibindo <b>{pageSize}</b> de <b>{totalItems}</b> items em{' '}
          <b>{totalPages}</b> páginas
        </span>
      ) : (
        <></>
      )}
      <Pagination
        className={classNames(
          'flex justify-center items-center mt-6',
          totalPages < 2 ? 'hidden' : 'block'
        )}
        currentPage={currentPage}
        nextLabel=''
        theme={{
          pages: {
            selector: {
              active: 'bg-pagination transition-all'
            }
          }
        }}
        previousLabel=''
        onPageChange={page => handlePageChange(page)}
        showIcons
        totalPages={isNaN(totalPages) ? 0 : totalPages}
      />
    </div>
  )
}

export default PaginationItem
