import { Steps } from 'intro.js-react'
import Cookie from 'js-cookie'

import StepStructure from '@/components/step-structure'

interface StepModalProps {
  onClose: () => void
  isOpen: boolean
  onBeforeExit: () => void
  image?: string
}

const STEPS_ONBOARDING = {
  FIRST: {
    title: 'Temos novidades pra você em nosso portal!',
    body: 'Vamos juntos conferir o que mudou?',
    step: 1
  },
  SECOND: {
    title: 'Canais de atendimento',
    body: 'Entre em contato com a gente, estamos prontos para te ajudar. ',
    step: 2
  },
  THIRD: {
    title: 'Notificações',
    body: 'Confira novidades e outras informações importantes para você. ',
    step: 3
  },
  FOURTH: {
    title: 'Central de ajuda',
    body: 'Encontre respostas para as principais dúvidas de uso do portal. ',
    step: 4
  },
  FIVE: {
    title: 'Busca',
    body: 'Encontre o que precisa, pesquisando palavras-chave relacionadas ao que procura. ',
    step: 5
  },
  SIX: {
    title: 'Parabéns, você já pode aproveitar nossas novidades! ',
    body: 'Agora que conhece os novos recursos disponíveis, você já pode usá-los para simplificar seu dia a dia. ',
    step: 6
  }
}
const StepModal: React.FC<StepModalProps> = ({
  onBeforeExit
}: StepModalProps & { onBeforeExit: () => void }) => {
  const isOnboardingEnabled = Cookie.get('userOnboarding') ?? 'false'
  const handleSkipOnboarding = () => {
    Cookie.set('userOnboarding', 'false')
  }

  return (
    <>
      {isOnboardingEnabled === 'true' && (
        <Steps
          onBeforeExit={onBeforeExit}
          onExit={() => console.log(null)}
          enabled={isOnboardingEnabled === 'true'}
          onComplete={handleSkipOnboarding}
          steps={[
            {
              element: '.seletor-do-elemento-2',
              intro: (
                <StepStructure
                  {...STEPS_ONBOARDING.FIRST}
                  imageSrc='\images\novidades.svg'
                />
              )
            },
            {
              element: '.contact-us',
              intro: (
                <StepStructure
                  {...STEPS_ONBOARDING.SECOND}
                  imageSrc='\images\suporte.svg'
                />
              )
            },
            {
              element: '.notifications',
              intro: (
                <StepStructure
                  {...STEPS_ONBOARDING.THIRD}
                  imageSrc='\images\notificacoes.svg'
                />
              )
            },

            {
              element: '.help-center',
              intro: (
                <StepStructure
                  {...STEPS_ONBOARDING.FOURTH}
                  imageSrc='\images\guia.svg'
                />
              )
            },
            {
              element: '.search-item',
              intro: (
                <StepStructure
                  {...STEPS_ONBOARDING.FIVE}
                  imageSrc='\images\barra_de_pesquisa.svg'
                />
              )
            },
            {
              element: '.congratulations',
              intro: (
                <StepStructure
                  {...STEPS_ONBOARDING.SIX}
                  imageSrc='\images\conclusao.svg'
                />
              )
            }
          ]}
          options={{
            prevLabel: 'Voltar',
            nextLabel: 'Próximo',
            doneLabel: 'Terminar',
            showSkipButton: true,
            hidePrev: true,
            exitOnEsc: false,
            exitOnOverlayClick: false,
            showBullets: false,
            tooltipClass: 'step-by-step',
            buttonClass: 'step-by-step__buttons'
          }}
          initialStep={0}
        />
      )}
    </>
  )
}

export default StepModal
